import React from 'react';

import { Button, Link } from '@mui/material';

interface IProps {
  link?: string;
  label: string;
}

const DashboardTitleButton = React.memo(({ link, label }: IProps) => (
  <Button
    component={Link}
    variant="outlined"
    color="darkBlue"
    sx={{ marginLeft: 2 }}
    href={link}
    target="_blank"
  >
    {label}
  </Button>
));

export default DashboardTitleButton;
