import { RecordNumber } from './record-number.type';
import { RecordString } from './record-string.type';

export type IUserSettingsFilters = Partial<
  Record<
    | 'school'
    | 'schoolYear'
    | 'schoolYearComparison'
    | 'dateFrom'
    | 'dateFromComparison'
    | 'preInscriptionSchoolYear'
    | 'preInscriptionDateFrom',
    string
  > & {
    preInscriptionDateToType: IPreInscriptionDateToType;
  }
>;

export interface IUserSettings {
  filters?: IUserSettingsFilters | null;
}

export type IUser = Record<'id' | 'firstName' | 'lastName' | 'email', string> & {
  settings: IUserSettings;
};
export type IParent = RecordNumber<'id'> &
  RecordString<'firstName' | 'lastName' | 'email' | 'phone'>;
export type IStudent = RecordNumber<'id'> &
  RecordString<'firstName' | 'lastName'> & {
    parent: IParent;
    status?: string | null;
  };

export enum IPreInscriptionDateToType {
  today = 'today',
  tomorrow = 'tomorrow',
}
