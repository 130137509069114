import { IFormSelectItem } from '../../types/form-select-item.type';

export const RESPONSE_DA_LIST: IFormSelectItem[] = [
  { code: 'accepted', name: 'pre_inscriptions.yes' },
  { code: 'refused', name: 'pre_inscriptions.no' },
  { code: 'no_da', name: 'pre_inscriptions.no_da' },
  { code: 'no_da', name: 'pre_inscriptions.en_course' },
  { code: 'no_da', name: 'pre_inscriptions.pass_information' },
  { code: 'no_da', name: 'pre_inscriptions.abandon' },
];

export const INSCRIPTION_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'pre_inscriptions.yes' },
  { code: 'no', name: 'pre_inscriptions.no' },
  { code: 'other', name: 'pre_inscriptions.other' },
  { code: 'back_to_ordinary_school', name: 'pre_inscriptions.back_to_ordinary_school' },
];

export const ENVOI_RAPO_LIST: IFormSelectItem[] = [
  { code: 'in_progress', name: 'pre_inscriptions.in_progress' },
  { code: 'yes', name: 'pre_inscriptions.yes' },
  { code: 'yes_by_themselves', name: 'pre_inscriptions.yes_by_themselves' },
  { code: 'no', name: 'pre_inscriptions.no' },
];

export const RESPONSE_RAPO_LIST: IFormSelectItem[] = [
  { code: 'accepted', name: 'pre_inscriptions.accepted' },
  { code: 'refused', name: 'pre_inscriptions.refused' },
  { code: 'no_answer', name: 'pre_inscriptions.no_answer' },
];

export const RECOURS_TA_LIST: IFormSelectItem[] = [
  { code: 'yes', name: 'pre_inscriptions.yes' },
  { code: 'no', name: 'pre_inscriptions.no' },
];

export const RESPONSE_TA_LIST: IFormSelectItem[] = [
  { code: 'positive', name: 'pre_inscriptions.positive' },
  { code: 'refused', name: 'pre_inscriptions.refused' },
];

export const PROCESSED_BY_LIST: IFormSelectItem[] = [
  { code: 'R', name: 'pre_inscriptions.pedagogy' },
  { code: 'N', name: 'pre_inscriptions.admission' },
];
