import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { ISales } from '../../../../types/sales.type';
import SalesListBody from '../SalesListBody';
import SalesListFooter from '../SalesListFooter';
import SalesListHead from '../SalesListHead';

interface IProps {
  sales: ISales[];
}

const SalesList = React.memo(({ sales }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <SalesListHead />
          <SalesListBody sales={sales} />
          <SalesListFooter sales={sales} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default SalesList;
