import React from 'react';

import { TableCell, TableRow } from '@mui/material';

interface IProps {
  groupTitle: string;
  children?: React.ReactNode;
  groupRowColSpan?: number;
}

const TableGroupRow = React.memo(({ groupTitle, groupRowColSpan = 1, children = null }: IProps) => (
  <TableRow sx={{ backgroundColor: '#ccc' }}>
    <TableCell colSpan={groupRowColSpan} sx={{ fontWeight: 'bold' }}>
      {children ? `${groupTitle}:` : groupTitle}
    </TableCell>

    {children}
  </TableRow>
));

export default TableGroupRow;
