import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SalesIncomeFilters from './components/SalesIncomeFilters';
import SalesIncomeList from './components/SalesIncomeList';
import { IFilters } from './types';
import { downloadSalesIncome, fetchSalesIncome } from '../../api/sales-income';
import ContentWrapper from '../../components/ContentWrapper';
import EmptyData from '../../components/EmptyData';
import ProgressSection from '../../components/ProgressSection';
import { SalesIncomeHelper } from '../../helpers/sales-income.helper';
import { ISalesIncome } from '../../types/sales-income.type';
import { HttpUtils } from '../../utils/http.utils';

const SalesIncome = React.memo(() => {
  const [salesIncome, setSalesIncome] = useState<ISalesIncome[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchSalesIncome(filters)
        .then((response) => setSalesIncome(response))
        .catch(() => setSalesIncome([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadSalesIncome(filters)
        .then((response) => HttpUtils.handleBlob(response, 'sales_income.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    if (SalesIncomeHelper.isEmpty(salesIncome)) {
      return <EmptyData />;
    }

    return <SalesIncomeList salesIncome={salesIncome} />;
  }, [isReady, isSubmitProcess, salesIncome]);

  return (
    <ContentWrapper title={t('sales_income.title')}>
      <SalesIncomeFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default SalesIncome;
