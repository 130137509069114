import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

import { IMenuItem } from '../../types';

interface IProps {
  item: IMenuItem;
}

const MenuItem = React.memo(({ item }: IProps) => {
  const { t } = useTranslation();

  return (
    <ListItemButton key={item.label} component={NavLink} to={item.route} end>
      <Tooltip title={t(`menu.${item.label}`)} placement="right">
        <ListItemIcon>{item.icon}</ListItemIcon>
      </Tooltip>

      <ListItemText primary={t(`menu.${item.label}`)} sx={{ color: 'text.secondary' }} />
    </ListItemButton>
  );
});

export default MenuItem;
