import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const InscriptionDetailsListHead = React.memo(() => {
  const { t } = useTranslation();

  return (
    <TableHead className="sticky-header-row">
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`student.id`)}</TableCell>
        <TableCell>{t(`student.last_name`)}</TableCell>
        <TableCell>{t(`student.first_name`)}</TableCell>
        <TableCell>{t(`parent.last_name`)}</TableCell>
        <TableCell>{t(`parent.first_name`)}</TableCell>
        <TableCell>{t(`parent.email`)}</TableCell>
        <TableCell>{t(`general.school_level`)}</TableCell>
        <TableCell>{t(`general.product_type`)}</TableCell>
        <TableCell>{t(`general.school_year`)}</TableCell>
        <TableCell>{t(`student.status`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default InscriptionDetailsListHead;
