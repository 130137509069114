import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IStaffEvolution } from '../../../../types/staff-evolution.type';
import { IFilters } from '../../types';
import StaffEvolutionListBody from '../StaffEvolutionListBody';
import StaffEvolutionListFooter from '../StaffEvolutionListFooter';
import StaffEvolutionListHead from '../StaffEvolutionListHead';

interface IProps {
  filters: IFilters;
  evolution: IStaffEvolution[];
}

const StaffEvolutionList = React.memo(({ filters, evolution }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <StaffEvolutionListHead filters={filters} />

          {evolution?.length > 0 && (
            <>
              <StaffEvolutionListBody
                evolution={evolution}
                displayProductType={filters.productType}
              />

              <StaffEvolutionListFooter
                evolution={evolution}
                displayProductType={filters.productType}
              />
            </>
          )}
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default StaffEvolutionList;
