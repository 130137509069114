import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import { ArrayUtils } from '../../utils/array.utils';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  onChange: <T>(event: SelectChangeEvent<T>) => void;
  useEmptyValue?: boolean;
};

const WEEKS: IFormSelectItem[] = ArrayUtils.makeRange(52).map((idx) => ({
  code: `${idx}`,
}));

const FormWeekSelect = React.memo(({ name = 'week', useEmptyValue = true, ...props }: IProps) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      label={t('general.week')}
      name={name}
      items={WEEKS}
      useEmptyValue={useEmptyValue}
      {...props}
    />
  );
});

export default FormWeekSelect;
