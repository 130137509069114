import { ApiHelper } from './api.helper';
import { ISalesIncome } from '../types/sales-income.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/SalesIncome/types';

export const fetchSalesIncome = async (filters: IFilters): Promise<ISalesIncome[]> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('sales/income')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadSalesIncome = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('sales/income/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
