import { JSX } from 'react';

import { RecordString } from '../../types/record-string.type';

export enum IMenuGroup {
  sales = 'sales',
  staff = 'staff',
  inscription = 'inscription',
  preInscription = 'preInscription',
}

export type IMenuItem = RecordString<'route' | 'label'> & {
  icon: JSX.Element;
  group?: IMenuGroup;
};

export type IMenuGroupIcon = {
  [key: string]: JSX.Element;
};
