import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from '../../../../constants/date.constant';
import { IProductType } from '../../../../types/product-type.type';
import { IFilters } from '../../types';
import StaffEvolutionListHeadPeriod from '../StaffEvolutionListHeadPeriod';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  filters: IFilters;
}

const StaffEvolutionListHead = React.memo(({ filters }: IProps) => {
  const { t } = useTranslation();

  const [period1Label, setPeriod1Label] = React.useState<string>('');
  const [period2Label, setPeriod2Label] = React.useState<string>('');

  React.useEffect(() => {
    const { period1DateFrom, period1DateTo, period2DateFrom, period2DateTo } = filters;

    const period1 = `${period1DateFrom.format(DATE_FORMAT)} - ${period1DateTo.format(DATE_FORMAT)}`;
    const period2 = `${period2DateFrom.format(DATE_FORMAT)} - ${period2DateTo.format(DATE_FORMAT)}`;

    setPeriod1Label(period1);
    setPeriod2Label(period2);
  }, [filters]);

  const renderPeriodCells = React.useMemo(() => {
    if (filters.productType?.length) {
      return (
        <StaffEvolutionListHeadPeriod
          filters={filters}
          period1Label={period1Label}
          period2Label={period2Label}
        />
      );
    }

    return (
      <>
        {[0, 1, 2].map((idx) => (
          <StaffEvolutionListHeadPeriod
            key={`header_${idx}`}
            filters={filters}
            period1Label={period1Label}
            period2Label={period2Label}
          />
        ))}

        <TableCell colSpan={2} />
      </>
    );
  }, [filters, period1Label, period2Label]);

  return (
    <TableHead className="sticky-header-multiline">
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={2}>{filters.school ?? ''}</TableCell>

        {(!filters.productType?.length || filters.productType === IProductType.COCO) && (
          <TableCell colSpan={3}>{t(`product_type.${IProductType.COCO.toLowerCase()}`)}</TableCell>
        )}

        {(!filters.productType?.length || filters.productType === IProductType.DISE) && (
          <TableCell colSpan={3}>{t(`product_type.${IProductType.DISE.toLowerCase()}`)}</TableCell>
        )}

        {(!filters.productType?.length || filters.productType === IProductType.CERTIFICATE) && (
          <TableCell colSpan={3}>
            {t(`product_type.${IProductType.CERTIFICATE.toLowerCase()}`)}
          </TableCell>
        )}

        {!filters.productType?.length && <TableCell colSpan={3}>{t('general.total')}</TableCell>}
      </TableRow>

      <TableRow sx={sxTableHead}>{renderPeriodCells}</TableRow>
    </TableHead>
  );
});

export default StaffEvolutionListHead;
