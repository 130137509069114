import React from 'react';

import List from '@mui/material/List';
import './style.scss';
import { useLocation } from 'react-router';

import MenuGroup from './components/MenuGroup';
import MenuItem from './components/MenuItem';
import { MENU_ITEMS } from './constants';
import { IMenuGroup } from './types';

const Menu = React.memo(() => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname?.length <= 1) {
      return;
    }

    const menuItem = MENU_ITEMS.find(({ route }) => route === pathname);

    if (menuItem?.group) {
      setExpanded(menuItem.group);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderEmptyGroup = React.useMemo(
    () => (
      <List component="nav" sx={{ paddingBottom: 0 }}>
        {MENU_ITEMS.filter((item) => !item?.group).map((item) => (
          <MenuItem item={item} key={item.label} />
        ))}
      </List>
    ),
    [],
  );

  const renderGroups = React.useMemo(
    () =>
      Object.keys(IMenuGroup).map((group) => (
        <MenuGroup
          key={group}
          isExpanded={expanded === group}
          group={group}
          handleChange={handleChange}
        />
      )),
    [expanded],
  );

  return (
    <>
      {renderEmptyGroup}
      {renderGroups}
    </>
  );
});

export default Menu;
