import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { ISalesIncome } from '../../../../types/sales-income.type';
import SalesIncomeListBody from '../SalesIncomeListBody';
import SalesIncomeListFooter from '../SalesIncomeListFooter';
import SalesIncomeListHead from '../SalesIncomeListHead';

interface IProps {
  salesIncome: ISalesIncome[];
}

const SalesIncomeList = React.memo(({ salesIncome }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <SalesIncomeListHead />
          <SalesIncomeListBody salesIncome={salesIncome} />
          <SalesIncomeListFooter salesIncome={salesIncome} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default SalesIncomeList;
