import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

interface IProps {
  statuses: string[];
}

const StaffStatisticsListHead = React.memo(({ statuses }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableHead className="sticky-header-multiline">
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={2}>{t(`general.product_type`)}</TableCell>
        <TableCell rowSpan={2}>{t(`general.school_level`)}</TableCell>
        <TableCell colSpan={statuses.length}>{t(`general.status`)}</TableCell>
        <TableCell rowSpan={2}>{t(`general.total`)}</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        {statuses.map((status) => (
          <TableCell key={`header_${status}`}>{status}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

export default StaffStatisticsListHead;
