import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SalesIncomeHelper } from '../../../../helpers/sales-income.helper';
import { ISalesIncomeGroupItem } from '../../../../types/sales-income.type';
import { NumberUtils } from '../../../../utils/number.utils';

const firstNumberKeys: (keyof ISalesIncomeGroupItem)[] = [
  'books',
  'fees',
  'delivery',
  'discounts',
  'total',
];

const numberKeys: (keyof ISalesIncomeGroupItem)[] = [
  'coco',
  'dise',
  'preInscription',
  'levelTotal',
];

const cellSx = { textAlign: 'center' };

interface IProps {
  salesIncome: ISalesIncomeGroupItem;
  isFirst: boolean;
  totalItems: number;
}

const SalesIncomeListItem = React.memo(({ salesIncome, isFirst, totalItems }: IProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell sx={cellSx}>{t(`school.${salesIncome.school}`)}</TableCell>
      <TableCell sx={cellSx}>{salesIncome.schoolYear}</TableCell>
      <TableCell sx={cellSx}>{salesIncome.schoolLevel}</TableCell>

      {numberKeys.map((key) => (
        <TableCell key={SalesIncomeHelper.makeKey(salesIncome, key)} sx={cellSx}>
          {NumberUtils.toCurrency(salesIncome[key] as number)}
        </TableCell>
      ))}

      {isFirst &&
        firstNumberKeys.map((key) => (
          <TableCell
            key={SalesIncomeHelper.makeKey(salesIncome, key)}
            rowSpan={totalItems}
            sx={cellSx}
          >
            {NumberUtils.toCurrency(salesIncome[key] as number)}
          </TableCell>
        ))}
    </TableRow>
  );
});

export default SalesIncomeListItem;
