import { ApiHelper } from './api.helper';
import { ISales } from '../types/sales.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/Sales/types';

export const fetchSales = async (filters: IFilters): Promise<ISales[]> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('sales')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadSales = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('sales/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
