import React from 'react';

import { TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { ISales } from '../../../../types/sales.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { NumberUtils } from '../../../../utils/number.utils';

interface IProps {
  sales: ISales[];
}

const SalesListFooter = React.memo(({ sales }: IProps) => {
  const { t } = useTranslation();

  const renderTotal = React.useMemo(() => {
    const fields: (keyof ISales)[] = [
      'total',
      'coco',
      'dise',
      'certificate',
      'preInscription',
      'books',
      'fees',
      'delivery',
      'discounts',
      'verify',
    ];

    return fields.map((field) => {
      const total = ArrayUtils.calculateTotal<ISales>(sales, field);

      return <TableCell key={`footer-${field}`}>{NumberUtils.toCurrency(total)}</TableCell>;
    });
  }, [sales]);

  return (
    <TableTotalFooter>
      <TableCell sx={{ fontWeight: 'bold' }}>{t('general.total')}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      {renderTotal}
    </TableTotalFooter>
  );
});

export default SalesListFooter;
