import React from 'react';

import { TableCell, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { ISalesIncome, ISalesIncomeSchoolLevel } from '../../../../types/sales-income.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { NumberUtils } from '../../../../utils/number.utils';

const sxCell: SxProps<Theme> = { fontWeight: 'bold', textAlign: 'center' };

const totalKeys: (keyof Omit<ISalesIncome, 'school' | 'schoolLevels'>)[] = [
  'books',
  'fees',
  'delivery',
  'discounts',
  'total',
];

const schoolLevelKeys: (keyof Omit<
  ISalesIncomeSchoolLevel,
  'schoolLevel' | 'groupCode' | 'schoolYear'
>)[] = ['coco', 'dise', 'preInscription', 'levelTotal'];

interface IProps {
  salesIncome: ISalesIncome[];
}

const SalesIncomeListFooter = React.memo(({ salesIncome }: IProps) => {
  const { t } = useTranslation();

  const renderTotal = React.useMemo(
    () =>
      schoolLevelKeys.map((key) => {
        const items = salesIncome.map((item) => item.schoolLevels).flat();

        return (
          <TableCell key={`total_footer_${key}`} sx={sxCell}>
            {NumberUtils.toCurrency(ArrayUtils.calculateTotal(items, key))}
          </TableCell>
        );
      }),
    [salesIncome],
  );

  return (
    <TableTotalFooter>
      <TableCell sx={{ fontWeight: 'bold' }}>{t('general.total')}</TableCell>
      <TableCell />
      <TableCell />
      {renderTotal}

      {totalKeys.map((key) => (
        <TableCell key={`total_footer_${key}`} sx={sxCell}>
          {NumberUtils.toCurrency(ArrayUtils.calculateTotal(salesIncome, key))}
        </TableCell>
      ))}
    </TableTotalFooter>
  );
});

export default SalesIncomeListFooter;
