const moneyFormatter = Intl.NumberFormat('fr-FR', {
  currency: 'EUR',
  currencyDisplay: 'symbol',
  currencySign: 'standard',
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
  minimumIntegerDigits: 1,
});

export class NumberUtils {
  /**
   * Format number to currency
   *
   * @param value
   */

  static toCurrency(value: number): string {
    return moneyFormatter.format(value);
  }
}
