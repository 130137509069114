import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const PreInscriptionSummaryListHead = React.memo(() => {
  const { t } = useTranslation();

  return (
    <TableHead className="sticky-header-multiline">
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={2}>{t('pre_inscriptions_summary.group_code')}</TableCell>
        <TableCell rowSpan={2}>{t(`general.registration_type`)}</TableCell>
        <TableCell colSpan={3}>{t('pre_inscriptions_summary.response_da')}</TableCell>
        <TableCell colSpan={3}>{t('pre_inscriptions_summary.after_rapo')}</TableCell>
        <TableCell colSpan={3}>{t('pre_inscriptions_summary.response_rapo')}</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        <TableCell>{t(`pre_inscriptions_summary.agreement`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.refusal`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.total`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.agreement`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.refusal`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.total`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.agreement`)}</TableCell>
        <TableCell>{t(`pre_inscriptions_summary.refusal`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default PreInscriptionSummaryListHead;
