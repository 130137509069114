import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IStaff } from '../../../../types/staff.type';
import { IOptions } from '../../types';
import StaffListBody from '../StaffListBody';
import StaffListFooter from '../StaffListFooter';
import StaffListHead from '../StaffListHead';

interface IProps {
  staff: IStaff[];
  options: IOptions;
}

const StaffList = React.memo(({ staff, options }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <StaffListHead options={options} />
          <StaffListBody staff={staff} options={options} />
          <StaffListFooter staff={staff} options={options} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default StaffList;
