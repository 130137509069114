import React from 'react';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

import DashboardTitleButton from './components/DashboardTitleButton';
import Statistics from './components/Statistics';
import ContentWrapper from '../../components/ContentWrapper';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Dashboard = React.memo(() => {
  const { t } = useTranslation();

  return (
    <ContentWrapper
      title={t('dashboard.title')}
      titleButtons={
        <>
          <DashboardTitleButton
            link={process.env.REACT_APP_WEBSHOP_EIB}
            label={t('dashboard.webshop_eib')}
          />

          <DashboardTitleButton
            link={process.env.REACT_APP_WEBSHOP_CSA}
            label={t('dashboard.webshop_csa')}
          />
        </>
      }
    >
      <Statistics />
    </ContentWrapper>
  );
});

export default Dashboard;
