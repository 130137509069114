import { ISalesIncome, ISalesIncomeGroup, ISalesIncomeGroupItem } from '../types/sales-income.type';

export class SalesIncomeHelper {
  /**
   * Checking of empty items
   *
   * @param salesIncome
   */
  static isEmpty(salesIncome: ISalesIncome[]): boolean {
    const total = salesIncome
      .map(({ schoolLevels }) => schoolLevels)
      .filter((items) => items.length).length;

    return total === 0;
  }

  /**
   * Grouped by school code
   *
   * @param salesIncome
   */
  static groupByCode(salesIncome: ISalesIncome[]): ISalesIncomeGroup {
    const grouped: ISalesIncomeGroup = {};

    salesIncome.forEach(({ schoolLevels, ...saleIncome }) => {
      schoolLevels.forEach((schoolLevel) => {
        const group = schoolLevel.groupCode;

        if (!grouped[group]) {
          grouped[group] = [];
        }

        grouped[group].push({
          ...saleIncome,
          ...schoolLevel,
        });
      });
    });

    return grouped;
  }

  /**
   * Make key
   *
   * @param salesIncome
   * @param key
   */
  static makeKey = (salesIncome: ISalesIncomeGroupItem, key: string): string =>
    `${salesIncome.groupCode}_${salesIncome.schoolLevel}_${key}`;
}
