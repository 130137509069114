import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const SalesListHead = React.memo(() => {
  const { t } = useTranslation();

  return (
    <TableHead className="sticky-header-row">
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`sales.date`)}</TableCell>
        <TableCell>{t(`sales.number`)}</TableCell>
        <TableCell>{t(`general.week`)}</TableCell>
        <TableCell>{t(`general.month`)}</TableCell>
        <TableCell>{t(`sales.customer`)}</TableCell>
        <TableCell>{t(`sales.total`)}</TableCell>
        <TableCell>{t(`sales.coco`)}</TableCell>
        <TableCell>{t(`sales.dise`)}</TableCell>
        <TableCell>{t(`sales.certificate`)}</TableCell>
        <TableCell>{t(`sales.pre_inscription`)}</TableCell>
        <TableCell>{t(`sales.books`)}</TableCell>
        <TableCell>{t(`sales.fees`)}</TableCell>
        <TableCell>{t(`sales.delivery`)}</TableCell>
        <TableCell>{t(`sales.discounts`)}</TableCell>
        <TableCell>{t(`sales.verify`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default SalesListHead;
