import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from '../../../../constants/date.constant';
import { ISales } from '../../../../types/sales.type';
import { DateUtils } from '../../../../utils/date.utils';
import { NumberUtils } from '../../../../utils/number.utils';

const numberKeys: (keyof ISales)[] = [
  'total',
  'coco',
  'dise',
  'certificate',
  'preInscription',
  'books',
  'fees',
  'delivery',
  'discounts',
  'verify',
];

interface IProps {
  sale: ISales;
}

const SalesListItem = React.memo(({ sale }: IProps) => {
  const { t } = useTranslation();

  const date = dayjs(sale.date);
  const year = date.format('YYYY');

  return (
    <TableRow sx={{ '& td': { textAlign: 'center' } }}>
      <TableCell>{t(`school.${sale.school}`)}</TableCell>
      <TableCell>{date.format(DATE_FORMAT)}</TableCell>
      <TableCell>{sale.number}</TableCell>

      <TableCell>
        {DateUtils.getWeekOfYear(date)} - {year}
      </TableCell>

      <TableCell>
        {DateUtils.getMonthName(date)} - {year}
      </TableCell>

      <TableCell>{sale.customer}</TableCell>

      {numberKeys.map((key) => (
        <TableCell key={`${sale.customer}_${key}`}>
          {NumberUtils.toCurrency(sale[key] as number)}
        </TableCell>
      ))}
    </TableRow>
  );
});

export default SalesListItem;
