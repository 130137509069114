import React from 'react';

import { TableBody } from '@mui/material';

import { SalesIncomeHelper } from '../../../../helpers/sales-income.helper';
import { ISalesIncome } from '../../../../types/sales-income.type';
import SalesIncomeListGroup from '../SalesIncomeListGroup';
import SalesIncomeListItem from '../SalesIncomeListItem';

interface IProps {
  salesIncome: ISalesIncome[];
}

const SalesIncomeListBody = React.memo(({ salesIncome }: IProps) => {
  const renderItems = React.useMemo(() => {
    if (!salesIncome?.length) {
      return null;
    }

    const grouped = SalesIncomeHelper.groupByCode(salesIncome);
    const totalRows =
      salesIncome.reduce((total, item) => total + item.schoolLevels.length, 0) +
      Object.keys(grouped).length;

    return Object.entries(grouped)
      .map(([group, salesIncomes], entryIdx) => (
        <React.Fragment key={`list_${group}`}>
          {salesIncomes.map((item, idx) => (
            <SalesIncomeListItem
              key={`list_${item.school}_${group}_${item.schoolLevel}`}
              salesIncome={item}
              isFirst={idx === 0 && entryIdx === 0}
              totalItems={totalRows}
            />
          ))}

          <SalesIncomeListGroup groupTitle={group} salesIncome={salesIncomes} />
        </React.Fragment>
      ))
      .flat();
  }, [salesIncome]);

  return <TableBody>{renderItems}</TableBody>;
});

export default SalesIncomeListBody;
