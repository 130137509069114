import React from 'react';

import Grid from '@mui/material/Grid';

interface IProps {
  title: string;
  children: React.ReactNode;
  titleButtons?: React.ReactNode;
}

const ContentWrapper = React.memo(({ title, children, titleButtons }: IProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={12} lg={12}>
      <h1>
        {title}

        {titleButtons ?? null}
      </h1>

      {children}
    </Grid>
  </Grid>
));

export default ContentWrapper;
