import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SalesFilters from './components/SalesFilters';
import SalesList from './components/SalesList';
import { IFilters } from './types';
import { downloadSales, fetchSales } from '../../api/sales';
import ContentWrapper from '../../components/ContentWrapper';
import EmptyData from '../../components/EmptyData';
import ProgressSection from '../../components/ProgressSection';
import { ISales } from '../../types/sales.type';
import { HttpUtils } from '../../utils/http.utils';

const Sales = React.memo(() => {
  const [sales, setSales] = useState<ISales[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchSales(filters)
        .then((response) => setSales(response))
        .catch(() => setSales([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadSales(filters)
        .then((response) => HttpUtils.handleBlob(response, 'sales.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    if (!sales.length) {
      return <EmptyData />;
    }

    return <SalesList sales={sales} />;
  }, [isReady, isSubmitProcess, sales]);

  return (
    <ContentWrapper title={t('sales.title')}>
      <SalesFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default Sales;
