import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';

export class DateUtils {
  /**
   * Get current study year
   */
  static getCurrentYear(): number {
    let year = dayjs().year();

    if (dayjs().month() < 8) {
      year -= 1;
    }

    return year;
  }

  /**
   * Get month name
   *
   * @param date
   */
  static getMonthName(date: dayjs.Dayjs): string {
    dayjs.extend(updateLocale);

    dayjs.locale(fr, {
      weekStart: 1,
    });

    return date.format('MMMM');
  }

  /**
   * Get week of year
   *
   * @param date
   */
  static getWeekOfYear(date: dayjs.Dayjs): number {
    dayjs.extend(weekOfYear);

    return date.week();
  }
}
