import React from 'react';

import { TableCell, TableRow } from '@mui/material';

import { ISalesIncomeGroupItem } from '../../../../types/sales-income.type';
import { ArrayUtils } from '../../../../utils/array.utils';
import { NumberUtils } from '../../../../utils/number.utils';

const numberKeys: ('coco' | 'dise' | 'preInscription' | 'levelTotal')[] = [
  'coco',
  'dise',
  'preInscription',
  'levelTotal',
];

interface IProps {
  groupTitle: string;
  salesIncome: ISalesIncomeGroupItem[];
}

const SalesIncomeListGroup = React.memo(({ groupTitle, salesIncome }: IProps) => (
  <TableRow sx={{ backgroundColor: '#ccc' }}>
    <TableCell colSpan={3} sx={{ fontWeight: 'bold' }}>
      {groupTitle}
    </TableCell>

    {numberKeys.map((key) => (
      <TableCell key={`group_total_${key}`} sx={{ textAlign: 'center' }}>
        {NumberUtils.toCurrency(ArrayUtils.calculateTotal(salesIncome, key))}
      </TableCell>
    ))}
  </TableRow>
));

export default SalesIncomeListGroup;
