import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { IPreInscriptionAcademy } from '../../../../types/pre-inscription-academy.type';
import PreInscriptionAcademyListBody from '../PreInscriptionAcademyListBody';
import PreInscriptionAcademyListHead from '../PreInscriptionAcademyListHead';

interface IProps {
  academies: IPreInscriptionAcademy[];
}

const PreInscriptionAcademyList = React.memo(({ academies }: IProps) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <PreInscriptionAcademyListHead academies={academies} />
          <PreInscriptionAcademyListBody academies={academies} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

export default PreInscriptionAcademyList;
