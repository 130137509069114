import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import { ArrayUtils } from '../../utils/array.utils';
import FormSelect from '../FormSelect';

const YEARS: IFormSelectItem[] = ArrayUtils.makeNumeric(2020, dayjs().year()).map((code) => ({
  code: `${code}`,
}));

type IProps = ControlSx & {
  value: string;
  name?: string;
  onChange: <T>(event: SelectChangeEvent<T>) => void;
  useEmptyValue?: boolean;
};

const FormYearSelect = React.memo(({ name = 'year', useEmptyValue = true, ...props }: IProps) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      label={t('general.year')}
      name={name}
      items={YEARS}
      {...props}
      useEmptyValue={useEmptyValue}
    />
  );
});

export default FormYearSelect;
