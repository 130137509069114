import React, { useEffect } from 'react';

import { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormHandleControl from '../../../../components/FormHandleControl';
import FormMonthSelect from '../../../../components/FormMonthSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import FormWeekSelect from '../../../../components/FormWeekSelect';
import FormYearSelect from '../../../../components/FormYearSelect';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchSalesFiltersData } from '../../../../hooks/useFetchSalesFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { IFilters } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 230 };

type IProps = IFilterList<IFilters>;

const SalesFilters = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }: IProps) => {
    const { t } = useTranslation();

    const { schools, schoolYears, isLoading } = useFetchSalesFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('sales');

    const { filters, setFilters, handleSelectChange } = useFilters<IFilters>(defaultFilters);

    useEffect(() => {
      if (isLoading || filters.schoolYear?.length || !schoolYears.length) {
        return;
      }

      setFilters({
        ...filters,
        schoolYear: schoolYears[schoolYears.length - 1].code,
      });
    }, [filters, isLoading, schoolYears, setFilters]);

    const isValid = React.useMemo(() => {
      if (!filters.schoolYear?.length) {
        return false;
      }

      if (filters.week?.length || filters.month?.length) {
        return filters.year?.length > 0;
      }

      return filters.dateFrom !== null && filters.dateTo !== null;
    }, [filters]);

    const handleDateChange = React.useCallback(
      (field: string, event: Dayjs | null) => {
        setFilters({
          ...filters,
          year: '',
          month: '',
          week: '',
          [field]: event,
        });
      },
      [filters, setFilters],
    );

    const handlePeriodChange = React.useCallback(
      <T = string,>(event: SelectChangeEvent<T>) => {
        const { name, value } = event.target;

        let resetFields = {};

        if (name !== 'year') {
          resetFields = {
            [name === 'month' ? 'week' : 'month']: '',
          };
        }

        setFilters({
          ...filters,
          dateFrom: null,
          dateTo: null,
          ...resetFields,
          [name]: value,
        });
      },
      [filters, setFilters],
    );

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="center">
            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
              controlSx={formFieldOptions}
            />

            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
            />

            <FormDatePicker
              label={t('general.date_from')}
              value={filters.dateFrom}
              onChange={(value) => handleDateChange('dateFrom', value)}
            />

            <FormDatePicker
              label={t('general.date_to')}
              value={filters.dateTo}
              onChange={(value) => handleDateChange('dateTo', value)}
            />

            <FormYearSelect
              value={filters.year}
              onChange={handlePeriodChange}
              controlSx={formFieldOptions}
            />

            <FormMonthSelect
              value={filters.month}
              onChange={handlePeriodChange}
              controlSx={formFieldOptions}
            />

            <FormWeekSelect
              value={filters.week}
              onChange={handlePeriodChange}
              controlSx={formFieldOptions}
            />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            isDisabled={!isValid}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

export default SalesFilters;
