import React from 'react';

import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../components/FormDatePicker';
import FormHandleControl from '../../../../components/FormHandleControl';
import FormRegistrationTypeSelect from '../../../../components/FormRegistrationTypeSelect';
import FormSchoolLevelSelect from '../../../../components/FormSchoolLevelSelect';
import FormSchoolSelect from '../../../../components/FormSchoolSelect';
import FormSelect from '../../../../components/FormSelect';
import FormSelectMultiple from '../../../../components/FormSelectMultiple';
import PaperWrapper from '../../../../components/PaperWrapper';
import { useFetchDefaultFiltersData } from '../../../../hooks/useFetchDefaultFiltersData';
import { useFetchPreInscriptionFiltersData } from '../../../../hooks/useFetchPreInscriptionFiltersData';
import { useFilters } from '../../../../hooks/useFilters';
import { IFilterList } from '../../../../types/filter-list.type';
import { translateSelectList } from '../../../../utils/translate.utils';
import {
  ENVOI_RAPO_LIST,
  INSCRIPTION_LIST,
  PROCESSED_BY_LIST,
  RECOURS_TA_LIST,
  RESPONSE_DA_LIST,
  RESPONSE_RAPO_LIST,
  RESPONSE_TA_LIST,
} from '../../constants';
import { IFilters } from '../../types';

const formFieldOptions: SxProps = { m: 1, width: 250 };

const PreInscriptionFilters = React.memo(
  ({ onSubmit, onExport, isSubmitProcess, isExportProcess }: IFilterList<IFilters>) => {
    const { t } = useTranslation();

    const {
      schools,
      schoolLevels,
      schoolYears,
      academies,
      departments,
      reasons,
      situations,
      isLoading,
    } = useFetchPreInscriptionFiltersData();

    const defaultFilters = useFetchDefaultFiltersData<IFilters>('preInscription');

    const { filters, handleSelectChange, handleDateChange, handleMultipleChoiceChange } =
      useFilters<IFilters>(defaultFilters);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (isLoading) {
        return;
      }

      onSubmit(filters);
    };

    return (
      <PaperWrapper isLoading={isLoading}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSchoolSelect
              onChange={handleSelectChange}
              schools={schools}
              value={filters.school}
              controlSx={formFieldOptions}
            />

            <FormSelect
              label={t('general.school_year')}
              name="schoolYear"
              value={filters.schoolYear}
              items={schoolYears}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
            />

            <FormRegistrationTypeSelect
              value={filters.registrationType}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
            />

            <FormDatePicker
              label={t('general.date_from')}
              value={filters.dateFrom}
              onChange={(value) => handleDateChange('dateFrom', value)}
              controlSx={{ width: 250 }}
            />

            <FormDatePicker
              label={t('general.date_to')}
              value={filters.dateTo}
              onChange={(value) => handleDateChange('dateTo', value)}
              controlSx={{ width: 250 }}
            />

            <FormSelect
              label={t('pre_inscriptions.response_da')}
              name="responseDa"
              value={filters.responseDa}
              items={translateSelectList(RESPONSE_DA_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.inscription')}
              name="inscription"
              value={filters.inscription}
              items={translateSelectList(INSCRIPTION_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.envoi_rapo')}
              name="envoiRapo"
              value={filters.envoiRapo}
              items={translateSelectList(ENVOI_RAPO_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.response_rapo')}
              name="responseRapo"
              value={filters.responseRapo}
              items={translateSelectList(RESPONSE_RAPO_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.recours_ta')}
              name="recoursTa"
              value={filters.recoursTa}
              items={translateSelectList(RECOURS_TA_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.response_ta')}
              name="responseTa"
              value={filters.responseTa}
              items={translateSelectList(RESPONSE_TA_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />

            <FormSelect
              label={t('pre_inscriptions.processed_by')}
              name="processedBy"
              value={filters.processedBy}
              items={translateSelectList(PROCESSED_BY_LIST, t)}
              onChange={handleSelectChange}
              controlSx={formFieldOptions}
              useEmptyValue
            />
          </Grid>

          <Grid container spacing={1} direction="row" alignItems="flex-start">
            <FormSchoolLevelSelect
              value={filters.schoolLevel}
              schoolLevels={schoolLevels}
              onChange={(value) => handleMultipleChoiceChange('schoolLevel', value)}
              controlSx={formFieldOptions}
            />

            <FormSelectMultiple
              name="academy"
              value={filters.academy}
              onChoose={(value) => handleMultipleChoiceChange('academy', value)}
              label={t('general.academy')}
              items={academies}
              controlSx={formFieldOptions}
            />

            <FormSelectMultiple
              name="department"
              value={filters.department}
              onChoose={(value) => handleMultipleChoiceChange('department', value)}
              label={t('pre_inscriptions.department')}
              items={departments}
              controlSx={formFieldOptions}
            />

            <FormSelectMultiple
              name="reason"
              value={filters.reason}
              onChoose={(value) => handleMultipleChoiceChange('reason', value)}
              label={t('pre_inscriptions.reason')}
              items={reasons}
              controlSx={formFieldOptions}
            />

            <FormSelectMultiple
              name="situation"
              value={filters.situation}
              onChoose={(value) => handleMultipleChoiceChange('situation', value)}
              label={t('pre_inscriptions.situation')}
              items={situations}
              controlSx={formFieldOptions}
            />
          </Grid>

          <FormHandleControl
            isSubmitProcess={isSubmitProcess}
            isExportProcess={isExportProcess}
            onExport={() => onExport(filters)}
          />
        </form>
      </PaperWrapper>
    );
  },
);

export default PreInscriptionFilters;
