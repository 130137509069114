import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DatasetIcon from '@mui/icons-material/Dataset';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import EuroIcon from '@mui/icons-material/Euro';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';
import TableChartIcon from '@mui/icons-material/TableChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { IMenuGroup, IMenuGroupIcon, IMenuItem } from './types';

const menuIconStyles = { color: 'text.secondary', marginRight: '0.6em' };

export const MENU_GROUP_ICONS: IMenuGroupIcon = {
  [IMenuGroup.sales]: <EuroIcon sx={menuIconStyles} />,
  [IMenuGroup.staff]: <GroupsIcon sx={menuIconStyles} />,
  [IMenuGroup.inscription]: <FindInPageIcon sx={menuIconStyles} />,
  [IMenuGroup.preInscription]: <FolderSharedIcon sx={menuIconStyles} />,
};

export const MENU_ITEMS: IMenuItem[] = [
  { route: '/', icon: <HomeIcon />, label: 'dashboard' },
  { route: '/sales', icon: <StoreIcon />, label: 'sales', group: IMenuGroup.sales },
  {
    route: '/sales/income',
    icon: <TableChartIcon />,
    label: 'sales_income',
    group: IMenuGroup.sales,
  },
  { route: '/staff', icon: <RecentActorsIcon />, label: 'staff', group: IMenuGroup.staff },
  {
    route: '/staff/students',
    icon: <DatasetIcon />,
    label: 'staff_students',
    group: IMenuGroup.staff,
  },
  {
    route: '/staff/evolution',
    icon: <AssessmentIcon />,
    label: 'staff_evolution',
    group: IMenuGroup.staff,
  },
  { route: '/staff/status', icon: <PieChartIcon />, label: 'status', group: IMenuGroup.staff },
  {
    route: '/staff/status/evolution',
    icon: <TrendingUpIcon />,
    label: 'status_evolution',
    group: IMenuGroup.staff,
  },
  {
    route: '/staff/statistics',
    icon: <DonutLargeIcon />,
    label: 'staff_statistics',
    group: IMenuGroup.staff,
  },
  {
    route: '/inscriptions',
    icon: <HowToRegIcon />,
    label: 'inscriptions',
    group: IMenuGroup.inscription,
  },
  {
    route: '/inscriptions/details',
    icon: <DatasetIcon />,
    label: 'inscriptions_details',
    group: IMenuGroup.inscription,
  },
  {
    route: '/pre-inscriptions',
    icon: <HowToRegIcon />,
    label: 'pre_inscriptions',
    group: IMenuGroup.preInscription,
  },
  {
    route: '/pre-inscriptions/summary',
    icon: <QueryStatsIcon />,
    label: 'pre_inscriptions_summary',
    group: IMenuGroup.preInscription,
  },
  {
    route: '/pre-inscriptions/academies',
    icon: <AccountBalanceIcon />,
    label: 'pre_inscriptions_academies',
    group: IMenuGroup.preInscription,
  },
  {
    route: '/pre-inscriptions/school',
    icon: <SchoolIcon />,
    label: 'pre_inscriptions_school',
    group: IMenuGroup.preInscription,
  },
  {
    route: '/pre-inscriptions/reasons',
    icon: <LeaderboardIcon />,
    label: 'pre_inscriptions_reasons',
    group: IMenuGroup.preInscription,
  },
];
