import React from 'react';

import { TableBody } from '@mui/material';

import { ISales } from '../../../../types/sales.type';
import SalesListItem from '../SalesListItem';

interface IProps {
  sales: ISales[];
}

const SalesListBody = React.memo(({ sales }: IProps) => (
  <TableBody>
    {sales.map((sale) => (
      <SalesListItem sale={sale} key={sale.number} />
    ))}
  </TableBody>
));

export default SalesListBody;
