import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const SalesIncomeListHead = React.memo(() => {
  const { t } = useTranslation();

  return (
    <TableHead className="sticky-header-row">
      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.school`)}</TableCell>
        <TableCell>{t(`general.school_year`)}</TableCell>
        <TableCell>{t(`general.school_level`)}</TableCell>
        <TableCell>{t(`sales_income.coco`)}</TableCell>
        <TableCell>{t(`sales_income.dise`)}</TableCell>
        <TableCell>{t(`sales_income.pre_inscription`)}</TableCell>
        <TableCell>{t(`sales_income.level_total`)}</TableCell>
        <TableCell>{t(`sales_income.books`)}</TableCell>
        <TableCell>{t(`sales_income.fees`)}</TableCell>
        <TableCell>{t(`sales_income.delivery`)}</TableCell>
        <TableCell>{t(`sales_income.discounts`)}</TableCell>
        <TableCell>{t(`sales_income.total`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

export default SalesIncomeListHead;
