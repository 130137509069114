import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  label?: string;
  schools: IFormSelectItem[];
  onChange: <T>(event: SelectChangeEvent<T>) => void;
  useEmptyValue?: boolean;
};

const FormSchoolSelect = React.memo(
  ({ schools, name = 'school', useEmptyValue = true, label, ...props }: IProps) => {
    const { t } = useTranslation();

    return (
      <FormSelect
        label={label ?? t('general.school')}
        name={name}
        items={schools}
        {...props}
        useEmptyValue={useEmptyValue}
      />
    );
  },
);

export default FormSchoolSelect;
