import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { MENU_GROUP_ICONS, MENU_ITEMS } from '../../constants';
import { AccordionDetailsStyles, AccordionStyles, AccordionSummaryStyles } from '../../styles';
import MenuItem from '../MenuItem';

interface IProps {
  isExpanded: boolean;
  group: string;
  handleChange: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
}

const MenuGroup = React.memo(({ isExpanded, group, handleChange }: IProps) => {
  const { t } = useTranslation();

  return (
    <AccordionStyles expanded={isExpanded} onChange={handleChange(group)}>
      <AccordionSummaryStyles
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${group}-content`}
        id={`${group}-header`}
      >
        <Tooltip title={t(`menu.group.${group}`)} placement="right">
          {MENU_GROUP_ICONS?.[group] ?? null}
        </Tooltip>

        <Typography component="span" sx={{ color: 'text.secondary' }}>
          {t(`menu.group.${group}`)}
        </Typography>
      </AccordionSummaryStyles>

      <AccordionDetailsStyles>
        <List component="nav">
          {MENU_ITEMS.filter((item) => item?.group === group).map((item) => (
            <MenuItem item={item} key={item.label} />
          ))}
        </List>
      </AccordionDetailsStyles>
    </AccordionStyles>
  );
});

export default MenuGroup;
