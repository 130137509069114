import { IFormSelectItem } from '../types/form-select-item.type';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const MONTHS_LIST: IFormSelectItem[] = [
  { code: '1', name: 'months.january' },
  { code: '2', name: 'months.february' },
  { code: '3', name: 'months.march' },
  { code: '4', name: 'months.april' },
  { code: '5', name: 'months.may' },
  { code: '6', name: 'months.june' },
  { code: '7', name: 'months.july' },
  { code: '8', name: 'months.august' },
  { code: '9', name: 'months.september' },
  { code: '10', name: 'months.october' },
  { code: '11', name: 'months.november' },
  { code: '12', name: 'months.december' },
];
