import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { IPreInscriptionAcademy } from '../../../../types/pre-inscription-academy.type';
import { StringUtils } from '../../../../utils/string.utils';

interface IProps {
  academies: IPreInscriptionAcademy[];
}

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const PreInscriptionAcademyListHead = React.memo(({ academies }: IProps) => {
  const { t } = useTranslation();

  const statisticsColumns = () => (
    <>
      <TableCell>{t('pre_inscriptions_academies.responses')}</TableCell>
      <TableCell>{t('pre_inscriptions_academies.accepted')}</TableCell>
      <TableCell>{t('pre_inscriptions_academies.refused')}</TableCell>
      <TableCell>{t('pre_inscriptions_academies.acceptedPercent')}</TableCell>
    </>
  );

  return (
    <TableHead className="sticky-header-multiline">
      <TableRow sx={sxTableHead}>
        <TableCell rowSpan={2} />
        <TableCell rowSpan={2}>{t('general.academy')}</TableCell>
        <TableCell colSpan={academies[0].situations.length + 1}>
          {t('pre_inscriptions_academies.total_section')}
        </TableCell>

        {academies[0].situations.map((situation) => (
          <TableCell colSpan={4} key={`section_head_${StringUtils.toKey(situation.code)}`}>
            {situation.name}
          </TableCell>
        ))}
        <TableCell colSpan={4}>{t('general.total')}</TableCell>
      </TableRow>

      <TableRow sx={sxTableHead}>
        {academies[0].situations.map((situation) => (
          <TableCell key={`section_${StringUtils.toKey(situation.code)}`}>
            {situation.name}
          </TableCell>
        ))}

        <TableCell>{t('general.total')}</TableCell>

        {academies[0].situations.map((situation) => (
          <React.Fragment key={`situation_${StringUtils.toKey(situation.code)}`}>
            {statisticsColumns()}
          </React.Fragment>
        ))}

        {statisticsColumns()}
      </TableRow>
    </TableHead>
  );
});

export default PreInscriptionAcademyListHead;
